.App {
  height: 100vh;
  display: flex;
	flex-direction: column;
}

.body-container {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: lightgray;
  margin: 0;
  padding: 0;
}

.view-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.view {
    background-color: white;
    box-sizing: border-box;
    align-items: stretch;
    flex: 1;
}