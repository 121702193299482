.requestVmMainOverride {
    min-width: 25%;
    min-height: 30%;
    width: auto;
}

.requestVmNav {
    min-height: 20%;
}

.request-vm-dialog-content {
    display: flex;
    flex-flow: column;
}