.confirmDeleteMainOverride {
    min-width: 550px !important;
}

.confirmDeleteMessageContent {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

.confirmDeleteMainOverride .warning-style {
    color: red;
    margin-bottom: 20px;
}

.confirmDeleteMainOverride .icon {
    font-size: 5em;
}

.confirmDeleteMainOverride span {
    font-size: 16px !important;
}
