.filter-detailslist {
    max-height: 15px;
    max-width: 200px;
    min-width: 200px;
    margin-bottom: 20px !important;
}

.is-selected {
    background-color: lightblue !important;
}

.ms-DetailsRow:hover {
    background-color: lightblue !important;
}

.vm-detailslist {
    overflow-x: hidden !important;
}

.vm-spinner {
    margin-left: 10px;
    min-width: 250px;
}

.vm-warning-icon {
    width: 20px;
}

.vm-warning-text {
    padding-left: 20px;
}

#header18-programId-name {
    padding-left: 20px;
}

.virtual-machine-actions-container {
    display: inline-flex;
    width: 100%;
}


.home-title-row-hidden {
    display: none;
}

.empty-table-logo {
    width: 240px;
    height: 240px;
}