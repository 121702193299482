.user-grid-header-cell {
    font-size: larger !important;
    font-weight: 600 !important;
}

.ms-List {
    cursor: default;
}

.user-grid-empty-table-logo {
    width: 280px;
    height: 280px;
}